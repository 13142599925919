/* heatmapStyles.css */

/* Color scales */
.react-calendar-heatmap .color-empty {
    fill: #ebedf0;
  }
  
  .react-calendar-heatmap .color-scale-1 {
    fill: #c6e48b;
  }
  
  .react-calendar-heatmap .color-scale-2 {
    fill: #7bc96f;
  }
  
  .react-calendar-heatmap .color-scale-3 {
    fill: #239a3b;
  }
  
  .react-calendar-heatmap .color-scale-4 {
    fill: #196127;
  }
  
  .react-calendar-heatmap text {
    font-family: 'Roboto', sans-serif;
    fill: #6a737d;
  }
  
  /* Cell size adjustments */
  .react-calendar-heatmap .day {
    width: 15px !important;
    height: 15px !important;
  }
  
  .react-calendar-heatmap .react-calendar-heatmap-week {
    display: flex;
  }
  
  .react-calendar-heatmap {
    max-width: 100%;
    overflow-x: auto;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .react-calendar-heatmap .day {
      width: 10px !important;
      height: 10px !important;
    }
  }
  